import React from "react"

import { Heading, Wrapper } from "components"

const IntroSection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {data.title && (
        <Wrapper id={id} pt="calc(32px + 48px + 128px)">
          {data.title && <Heading size={900}>{data.title}</Heading>}
          {data.subtitle && (
            <Heading
              size={600}
              mt={["layout.2", "layout.4"]}
              fontWeight="normal"
            >
              {data.subtitle}
            </Heading>
          )}
        </Wrapper>
      )}
    </>
  )
}

export default IntroSection
