import React from "react"

// Project components
import { Box, FlexGrid, ServiceCard, Wrapper } from "components"

const ServicesSection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {data.services && (
        <Wrapper id={id}>
          {data.services && (
            <Box mx={["-8px", "-16px"]}>
              <FlexGrid
                content={data.services.map(service => ({
                  width: ["100%", "50%", "33%"],
                  children: (
                    <ServiceCard
                      image={service.image}
                      title={service.title}
                      color={service.color}
                      to={service.to}
                    />
                  ),
                }))}
                gutterX={[2, 3, 16]}
                gutterY={[2, 4]}
              />
            </Box>
          )}
        </Wrapper>
      )}
    </>
  )
}

export default ServicesSection
