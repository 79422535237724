import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

import { Divider, NextPage, SEO } from "components"

// Page sections
import IntroSection from "./_introSection"
import ServicesSection from "./_servicesSection"

const ServicesPage = () => {
  const staticData = useStaticQuery(graphql`
    {
      prismicServices {
        data {
          seo_title {
            text
          }
          seo_description {
            text
          }
          seo_image {
            url
            alt
          }
          page_title {
            text
          }
          page_subtitle {
            text
          }
          services {
            service {
              uid
              document {
                ... on PrismicService {
                  data {
                    main_image {
                      fluid(maxWidth: 600) {
                        ...GatsbyPrismicImageFluid
                      }
                      alt
                    }
                    name {
                      text
                    }
                    color
                  }
                }
              }
            }
          }
          next_page_image {
            fluid(maxWidth: 600) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          next_page_title {
            text
          }
          next_page_button_text
          next_page_button_link {
            slug
          }
        }
      }
    }
  `)
  const previewData =
    typeof window !== "undefined" && window.__PRISMIC_PREVIEW_DATA__
  const finalData = mergePrismicPreviewData({ staticData, previewData })
  return (
    <>
      <SEO
        title={
          finalData.prismicServices.data.seo_title &&
          finalData.prismicServices.data.seo_title.text
        }
        description={
          finalData.prismicServices.data.seo_description &&
          finalData.prismicServices.data.seo_description.text
        }
        image={
          finalData.prismicServices.data.seo_image &&
          finalData.prismicServices.data.seo_image.url
        }
        imageAlt={
          finalData.prismicServices.data.seo_image &&
          finalData.prismicServices.data.seo_image.alt
        }
      />
      {/* ============ INTRO ============ */}
      <IntroSection
        id="services-intro"
        data={{
          title: "Services",
          subtitle:
            finalData.prismicServices.data.page_subtitle &&
            finalData.prismicServices.data.page_subtitle.text,
        }}
      />
      {/* ============ SERVICES ============ */}
      {finalData.prismicServices && finalData.prismicServices.data.services && (
        <ServicesSection
          id="services-services"
          data={{
            services: finalData.prismicServices.data.services.map(
              ({ service }) => {
                return {
                  image:
                    service.document.data.main_image &&
                    service.document.data.main_image.fluid &&
                    service.document.data.main_image,
                  title:
                    service.document.data.name &&
                    service.document.data.name.text,
                  color: service.document.data.color,
                  to: "/services/" + service.uid + "/",
                }
              }
            ),
          }}
        />
      )}
      {finalData.prismicServices.data.next_page_title &&
        finalData.prismicServices.data.next_page_title.text && (
          <>
            {/* ============ DIVIDER ============ */}
            <Divider id="services-divider" />

            {/* ============ NEXT PAGE ============ */}
            <NextPage
              id="services-nextpage"
              image={finalData.prismicServices.data.next_page_image}
              title={
                finalData.prismicServices.data.next_page_title &&
                finalData.prismicServices.data.next_page_title.text
              }
              buttonLabel={finalData.prismicServices.data.next_page_button_text}
              buttonTo={
                "/" +
                finalData.prismicServices.data.next_page_button_link.slug +
                "/"
              }
            />
          </>
        )}
    </>
  )
}

export default ServicesPage
